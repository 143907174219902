<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">
                <v-card>

                    <v-form @submit.prevent="passwordReset" ref="resetForm">
                        <v-card-title>{{ $t('password_reset') }}</v-card-title>

                        <v-card-text>
                            <v-text-field
                                    id="passwordField"
                                    :label="$t('password')"
                                    type="password"
                                    v-model="password"
                                    :rules="passwordRules"
                                    required
                            />
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn id="submitButton" type="submit" color="info">
                                <v-icon dark left>mdi-send</v-icon>
                                {{ $t('password_reset') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import userService from '@/services/UserService.js'

    export default {
        name: 'PasswordReset',

        data() {
            return {
                password: '',
                token: '',

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',

                passwordRules: [
                    value => !!value || this.$t('required'),
                ],
            }
        },

        methods: {
            passwordReset() {
                if (this.$refs.resetForm.validate()) {

                    // Fetch route parameter
                    this.token = this.$route.params.token

                    userService.passwordReset(
                        {
                            password: this.password,
                            token: this.token,
                        }
                    )
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('password_has_been_reset')

                            // Redirect to login page
                            this.$router.push('/login')
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },
        },
    };
</script>
